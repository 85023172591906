.input .nav-tabs .nav-link.active {
  height: 80px !important;
  width: 200px !important;
  background-color: #ffffff !important;
  color: #ff90ff !important;
  border-color: #ff90ff !important;
  font-weight: bold !important;
  font-size: 16px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.input .nav-tabs .nav-link {
  height: 80px !important;
  width: 200px !important;
  text-align: center !important;
  margin-left: 15px;
  border-radius: 9px !important;
  background-color: #ffffff !important;
  border-color: #3c0e92 !important;
  color: #3c0e92 !important;
  font-size: 16px;
 
}

.input .nav-tabs .nav-link:hover {
  height: 80px !important;
  width: 200px !important;
  text-align: center !important;
  margin-left: 15px;
  border-radius: 9px !important;
  background-color: #ffffff !important;
}

.form-label {
  margin-left: 30px;
  font-size: 12px;
}

.form-control {
  text-align: center;
  border-radius: 0;
  border-color: black;
  font-size: 14px;
}

.btn-primary {
 
  margin-left: 30px;
  border-radius: 0;
  background-color:rgb(76, 17, 171);
  border-top: rgb(76, 17, 171);
  width: 200px;
  height: 35px;
  font-size: 14px;
}

.btn-primary:hover {
  
  margin-left: 30px;
  border-radius: 0;
  background-color: rgb(76, 17, 171);
  border-top: rgb(76, 17, 171);
  width: 200px;
  height: 35px;
  font-size: 14px;
}

.bbeuForm {

  margin-top: 20px;
  display: inline-block !important;
  width: 100%;

  border: 1px solid #ff90ff !important;

  border-radius: 10px;
}
.form-group {
  margin-bottom: 2.5rem;
  margin-left: 6px;
}
.form-control {
 width:99%;
border-color: #8080808c
}




@media screen and (min-width: 330px) and (max-width: 552px) {
  .btn-primary {

    margin-left: 30px;
    border-radius: 0;
    background-color:rgb(76, 17, 171);
    border-top: rgb(76, 17, 171);
    width: 200px;
    height: 35px;
    font-size: 14px;
  }
  
  .btn-primary:hover {
  
    margin-left: 30px;
    border-radius: 0;
    background-color: rgb(76, 17, 171);
    border-top: rgb(76, 17, 171);
    width: 200px;
    height: 35px;
    font-size: 14px;
  }
  }