@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    .margin-ie{
      margin-top:20px
    }
  
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    .margin-for-download{
      margin-top:25px
    }
  
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    .margin-for-download-t1{
      margin-top:25px
    }
  
  }
  
  
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    .addMarginIE{
      margin-right:8px
    }
  
  }
  .font-space-for-result-summary{

    font-size: 13px
  }

  .style-download{
    font-size:14px;
    font-weight:bold;
    
  }

  .style-download-t2{
    font-size:14px;
    font-weight: bold;
    
  }



  