
.ex1 {
  overflow: auto;
  opacity:0.1;
  
}
.skills{
  width:500px;
  margin:60px auto;
  color:black;
}
.skills li{
 margin:20px 0;
}
.bar{
  background: #353b48;
  display: block;
  height:2px;
  border:1px solid rgba(0,0,0,0,3);
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 5px #2187e7b3;
}
.bar span{
  height:2px;
  float:left;
  background: #2187e7;

}
.html{
  width:90%;
  animation: html 60s;
}

@keyframes html{
  0%{
    width:0%
  }
  100%{
    width:90%
  }
}


.loaderName{
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: 0 5px;
  display: inline-flex;
}

.tabHome{
margin-top: 10px;
margin-bottom: 2px;
color:#FFFFFF;
border-bottom: 1px solid #FF90FF;
margin-left: 20px;
padding-bottom: 3px;
font-size: 15px;
text-decoration-thickness: 5px;

}
.tabHome:hover{
  margin-top: 10px;
  color:#FFFFFF;
  padding-bottom: 3px;
  border-bottom: 1px solid #FF90FF;
  border-bottom: #FF90FF;
  margin-left: 20px;
  font-weight: bold;
  font-size: 15px;
  }

.circle {
  height: 100px;
  width: 100px;
  padding-top:7px;
  color: #FFFFFF;
  text-align: center; 
  font-weight:bold;
  font-stretch: expanded;
  border-radius: 50%;
  font-size: 20px;
  border: 1px solid  #FF90FF !important;

  background: none !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.testButton {
  height: 140px;
  width: 140px;
  padding-top:5px;
  color: #FFFFFF;
  text-align: center; 
  font-weight:bold;
  font-stretch: expanded;
  border-radius: 50%;
  font-size: 20px;
  border: 1px solid  #FF90FF !important;
  margin-left: 1rem;
  margin-top: 1.5rem;
  position: absolute;
  background: none !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: pulse 3s infinite;
}

.testButton:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    -moz-box-shadow:  0 #FF90FF;
    box-shadow: 0 0 0 0 #FF90FF;
  }
  80% {
      -moz-box-shadow: 0 0 0 20px none;
      box-shadow: 0 0 0 25px rgba(204,169,44, 0);
      
  }
  100% {
      -moz-box-shadow: 0 0 0 none;
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
.rectangleGuidance{
  margin-top: -5px;
  
  display:inline-block !important;
  width: 70px;
  color: #FF90FF;
  text-align: center; 
  font-size: 14px;
  border:  none !important;
  background: none !important;
  

}


.rectangle{
  
 margin-left: 15px;
  height: 27px;
  margin-top: 39px;
  width: 180px;
  color: #FFFFFF;
  text-align: center; 
  font-size: 15px;
  border: 1px solid  #FF90FF !important;
  background: none !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.hoverCircle{
  height: 20px;
  width: 20px;
  color: #FFFFFF;
  text-align: center; 
  font-weight:bold;
  
  border-radius: 90%;
  font-size: 10px;
  border: 1px solid  #FFFFFF !important;
  box-shadow: 1px solid ;
  margin-left: 10px;
  margin-top:43px;
  background: none !important;

  
  position: absolute;
}

.line{
  
  margin-top: 2.2rem;
    margin-left: 1rem;
    height: 2rem;
  
  width:0px !important;
  border: 1px solid  #FF90FF !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


}
.nav {
  margin-top:13px;
  margin-left: 30px;
  
}

.nav-tabs {
  border-bottom: none;
  
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
  background-color: none !important;
  border-color: none !important;
  color:white;
  
}



.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: rgb(76, 17, 171) !important;
  border-color: rgb(76, 17, 171) !important;
  color:white;
  border-bottom-color: #FF90FF !important;
  border-bottom-width: 1px;
  font-weight: bold;
  
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover {
  background-color: rgb(76, 17, 171) !important;
  border-width: 0px;
  color:white;
  border-bottom-color: #FF90FF !important;
  border-bottom-width: 1px;
  border-bottom: 5vh;
  transition: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.container {
  
  
  
}

x

.carousel .control-arrow:hover {
background: #FF90FF !important;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.carousel .control-arrow{
  padding:30px !important;
  margin-top: 400px !important;
  
  
  }
.carousel.carousel-slider .control-arrow:hover{
  background: none !important;
  
}


.carousel .control-dots{
  padding-right: 50px !important;
  padding: 15px !important;

}

.carousel .control-dots .dot {

box-shadow: none !important;
width: 10px !important;
height:10px !important;
border-radius: 60%;
border: #FF90FF !important;
opacity: 1;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.progresr{
  background-color:"#F90FF"
}

.carousel .control-dots .dot:hover {

  box-shadow: none !important;
  width:9px !important;
  height:9px !important;
  border-radius: 50%;
  border: #FF90FF !important;
  background: #FF90FF !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

.carousel .control-dots .dot.selected{
  background: #FF90FF !important;
  border: none !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


/* important css do not remove */
.full-width {
  width: 100%;
}



.bigScreen{
  width: 100%;
   max-width: 190px;
   align-content:center;
   margin-left:40px
}
.successTestInit{
  margin-left:43px;
  margin-top:-10px
}
.successTestInitial{
  margin-left:40px;
  margin-top:5px
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  .space-for-ie {
    
    margin-left: -185px
  }
}
.space-for-header {
  margin-top: 50px;
}

.green {
  color: #3EAC9F;
  
}
@media only screen and (max-width: 765px) {
  .successTestInitial {
    margin-left: 0px;
  }
}


@media screen and (max-width: 540px) {

  .rectangle{
  
    margin-left: 15px;
     height: 35px;
     margin-top: 29px;
     width: 100px;
     color: #FFFFFF;
     text-align: center; 
     font-size: 10px;
     border: 1px solid  #FF90FF !important;
     background: none !important;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   }

   .circle {
    height: 80px;
    width: 80px;
    padding-top:7px;
    color: #FFFFFF;
    text-align: center; 
    font-weight:bold;
    font-stretch: expanded;
    border-radius: 50%;
    font-size: 16px;
    border: 1px solid  #FF90FF !important;
  
    background: none !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .line{
  
    margin-top: 27px;
      margin-left: 1rem;
      height: 40px;
    
    width:0px !important;
    border: 1px solid  #FF90FF !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  
  }
  .smallScreen{
    margin-left: 0px;
    margin-top: 10px;
  }
  .successTestInitial{
    margin-left:0px;
    margin-top:5px;font-size: 12px;
  }
  .successTestInit{
    margin-left:0px
    ;font-size: 11px;
  }
}
@media screen and (min-width: 540px) and (max-width: 780px) {
  .smallScreen{
    margin-left: 0px;
    margin-top: 10px;
  }
  .successTestInit{
    margin-left:0px
    
  }
}

@media screen and (min-width: 350px) and (max-width: 760px) {
  .value-css{
    margin-left: 0px;
    font-size:18px;
    font-weight:bold;
    margin-top:20px
  }
}
@media screen and (min-width: 760px) {
  .value-csss{
    margin-left: 65px;
    font-size:20px;
    font-weight:bold
  }
}
